// import logo from './logo.svg';
import React, { useRef, useState } from 'react';
import './App.css';
import emailjs from '@emailjs/browser';

// SVGs imports
import logo from "./assets/Images/logo2.svg";
import contactUs from "./assets/Images/contactUs.svg";
import sunIcon from "./assets/Images/sunIcon.svg";
import quoteIcon from "./assets/Images/quoteIcon.svg";
import centerImage from "./assets/Images/centerImage.svg";
import grassIcon from "./assets/Images/grassIcon.svg";
import horizontalLine from "./assets/Images/horizontalLine.svg";
import verticalLine from "./assets/Images/verticalLine.svg";
import photo1 from "./assets/Images/photo1.svg";
import photo2 from "./assets/Images/photo2.svg";
import photo3 from "./assets/Images/photo3.svg";
import RateStar from "./assets/Images/RateStar.svg";
import vertical from "./assets/Images/vertical.svg";
import horizontal from "./assets/Images/horizontal.svg";
import gotoArrow from "./assets/Images/gotoArrow.svg";
import Nikita from "./assets/Images/Nikita.svg";
import palak from "./assets/Images/palak.svg";
import Santa from "./assets/Images/Santa.svg";
import Janki from "./assets/Images/Janki.svg";
import Rupesh from "./assets/Images/Rupesh.svg";
import emailIcon from "./assets/Images/emailIcon.svg";
import phoneIcon from "./assets/Images/phoneIcon.svg";
import waIcon2 from "./assets/Images/waIcon2.svg";

function App() {

  const form = useRef();

  const [isDisabled, setIsDisabled] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”
    // console.log("--",form);

    emailjs.sendForm('service_tj9uq0w', 'template_0ikm00k', form.current, 'Li4Kn58pmmF_fYRn7')
      .then((result) => {
        setIsDisabled(true);
        // form.current.value.reset();
        // show the user a success message
      }, (error) => {
        // show the user an error
      });
  };

  return (
    <div>
      <div className="kw-container">
        <nav className="navbar navbar-light">
          <a className="navbar-brand" href="/"><img alt="..." src={logo} className="navbar-brand" /></a>
          <div className="media-hidden">
            <ul className="navbar-nav ms-auto nav-background">
              <li className="nav-item ">
                <a className="nav-link" href="/"><img alt="..." src={contactUs} className="navbar-brand" /></a>
              </li>
            </ul>
          </div>
        </nav>
        {/* <!-- Navbar ends --> */}

        <div className="row">
          <div className="col-lg-12 col-sm-12 col-md-12">
            <img alt="..." src={sunIcon} width="50" height="50" className="sunIcon" />
          </div>
        </div>

        {/* <!-- SunIcon ends --> */}

        <div className="row">
          <div className="col-md-9 col-lg-9 col-sm-12">
            <div className="mainHeading center-align">Get Benefits of</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-12 col-sm-12">
            <div className="mainHeading center-align">
              <span className="yoga">Yoga,</span>
              <span className="ayurveda">Ayurveda,</span>
              <span className="naturopathy">Naturopathy</span>
            </div>
          </div>
        </div>


        {/* <!-- Main Heading ends --> */}

        <div className="row topgap20">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div><img alt="..." src={quoteIcon} width="25" height="25" /></div>
            <div className="font-20">Yoga is not an ancient myth buried in oblivion. It is the most valuable inheritance of the
              present. It is the essential need of today and the culture of tomorrow.</div>
            <div className="font-20">- Swami Satyananda Saraswati</div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="d-flex justify-content-center"><img alt="..." src={centerImage} className="w-100 topgap10Media leftgap20" /></div>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-12 media-hidden">&nbsp;</div>
          <div className="col-lg-2 col-md-2 col-sm-12 media-hidden">
            <div className="topgap100 leftgap20">
              <div><img alt="..." src={grassIcon} width="50" height="50" /></div>
              <div className="font-20 ">PERSONAL AND GROUP YOGA CLASSES</div>
            </div>
          </div>
        </div>

        {/* <!-- Center Content ends --> */}

      </div>

      <div className="grayContainer">
        <div className="row">
          <div className="col-lg-7 col-md-7 col-sm-12">
            <div className="row topgap70">
              <div className="col-lg-3 col-md-3">
                <div className="font-36 color-gray topgap40">What we Provide?</div>
              </div>
              <div className="col-lg-1 col-md-1">
                <div className="provideLineH"><img alt="..." src={horizontalLine} /></div>
                <div className="provideLineV"><img alt="..." src={verticalLine} /></div>
              </div>
              <div className="col-lg-8 col-md-8 leftgap40Neg topgap20Neg topgap10Media">
                <ul className="font-20">
                  <li>Personal & Group Yoga Classes</li>
                  <li>Pregnancy & Fertility Yoga</li>
                  <li>Meditation</li>
                  <li>Pranayama & Mudra Bandha</li>
                  <li>Zumba & Dance Cardio Workout</li>
                  <li>Tantra & Kundalini Yoga Sadhna</li>
                  <li>Corporate Yoga Classes & Fitness Workshop</li>
                  <li>Special programme on Stress Management</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-1 col-md-1 col-sm-12">&nbsp;</div> */}
          <div className="col-lg-5 col-md-5 col-sm-12">
            <div className="row">
              <div className="col-lg-3 col-md-3">
                <img alt="..." src={photo1} className="topgap20" />
              </div>
              <div className="col-lg-9 col-md-9">
                <div className="font-20">Acharya Kamalshri</div>
                <div className="color-gray font-14">B.A. M.A. in Yoga(Gold Medalist)<br />
                  Diploma in Diet and Nutrition<br />
                  QCI certified Yoga Teacher from Indian Government<br />
                  300 TTC Yoga alliance USA</div>
              </div>
            </div>
            <div className="row topgap30">
              <div className="col-lg-3 col-md-3">
                <img alt="..." src={photo2} />
              </div>
              <div className="col-lg-9 col-md-9">
                <div className="font-20">Shri Ram Sharma</div>
                <div className="color-gray font-14">BSc. MSc. in Yoga Science<br />
                  Diploma in Yoga Science & Alternative Therapy</div>
              </div>
            </div>
            <div className="row topgap30">
              <div className="col-lg-3 col-md-3">
                <img alt="..." src={photo3} className="topgap10" />
              </div>
              <div className="col-lg-9 col-md-9">
                <div className="font-20">Ram Niranjan Sharma</div>
                <div className="color-gray font-14">B.A. M.A. in Yoga Science<br />
                  Diploma in Yoga Science & Alternative Therapy<br />
                  Master Diploma in Advance acupressure Therapy</div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Introduction ends --> */}

        <div className="row topgap20">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div id="carouselExampleIndicators" className="carousel carousel-dark slide" data-bs-ride="carousel">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active"
                  aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                  aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                  aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"
                  aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4"
                  aria-label="Slide 5"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row">
                    <div className="col-sm-1">
                      <img alt="..." src={palak} className="reviewImg" />
                    </div>
                    <div className=" col-sm-4">
                      <div className="reviewName">Palak Singh</div>
                      <div className="reviewDate">1/1/2023</div>
                      <img alt="..." src={RateStar} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-1">&nbsp;</div>
                    <div className="col-sm-9">
                      <div className="reviewPara">Teacher is a bit harsh but knowledge of yoga is very deep, my problem of the period when doctors could not correct it for 6 years, Kamal cured me within 13 months. That too by changing my lifestyle and feeding only a simple diet in the name of Satvik diet. Teaching style is simple but very effective, it worls result oriented. My husband too cured his blood prssure within 2 months. Thanks, Kamal</div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-sm-1">
                      <img alt="..." src={Janki} className="reviewImg" />
                    </div>
                    <div className=" col-sm-4">
                      <div className="reviewName">Janki Pahlavi Reddi</div>
                      <div className="reviewDate">1/1/2023</div>
                      <img alt="..." src={RateStar} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-1">&nbsp;</div>
                    <div className="col-sm-9">
                      <div className="reviewPara">Fantastic Teacher. My father and my husband have cured blood pressure, and cholestrol with Kamal Sir's classes. Amazing simple traditional teaching skills.Personally, I like him, he is not only out teacher but also a family wellness coach.</div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-sm-1">
                      <img alt="..." src={Nikita} className="reviewImg" />
                    </div>
                    <div className=" col-sm-4">
                      <div className="reviewName">Nikita Patel</div>
                      <div className="reviewDate">1/1/2023</div>
                      <img alt="..." src={RateStar} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-1">&nbsp;</div>
                    <div className="col-sm-9">
                      <div className="reviewPara">Hello, I am Nikita from Los Angeles, California. Kamal sir's yoga class is very awesome, yoga gave me the good fortune to become a mother, when today's science was failing since last 10 years, then in 1 year class under Kamal sir's guidance my family is complete, this happiness for me cannot be expressed in words, thanks Kamal Sir.</div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-sm-1">
                      <img alt="..." src={Rupesh} className="reviewImg" />
                    </div>
                    <div className=" col-sm-4">
                      <div className="reviewName">Dr. Rupesh Nanda</div>
                      <div className="reviewDate">1/1/2023</div>
                      <img alt="..." src={RateStar} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-1">&nbsp;</div>
                    <div className="col-sm-9">
                      <div className="reviewPara">Kamal is an amazing personality, his knowledge of yoga is so deep. I feel real life enjiyed after a yoga session. Kamal  guides us a pure diet for good health, it works properly on my body, my family is happy with him. </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-sm-1">
                      <img alt="..." src={Santa} className="reviewImg" />
                    </div>
                    <div className=" col-sm-4">
                      <div className="reviewName">Santa Clara</div>
                      <div className="reviewDate">1/1/2023</div>
                      <img alt="..." src={RateStar} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-1">&nbsp;</div>
                    <div className="col-sm-9">
                      <div className="reviewPara">I am Santa Clara, CA and have been doing yoga with Kamalji via zoom for almost 2 years. I have seen amazing benefits. My anxiety is almost finished. I have also reduced my colitis medication. Thank you so much for your guidance. Your videos have been absolutely fantastic. </div>
                    </div>
                  </div>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Carousel ends */}

      <div className="kw-container">
        <div className="class-block">
          <div className="font-36 ms-5">Group <span className="color-gray">Class</span></div>
          <div className="row topgap40">
            <div className="col-lg-3 col-md-3">
              <div className="time-block" style={{ backgroundColor: "#87B5D0" }}>
                <div className="font-26">London</div>
                <div className="row topgap40 topgap10Media">
                  <div className="col-lg-3 col-md-3">
                    <div className="font-48 font-inter">01</div>
                  </div>
                  <div className="col-lg-1 col-md-1">
                    <div><img alt="..." src={vertical} className="media-hidden" /></div>
                  </div>
                  <div className="col-lg-7 col-md-7">
                    <div className="font-14">Yoga Workout</div>
                    <div className="font-15 color-gray topgap10">Mon | Wed | Fri</div>
                    <div className="font-14 topgap10 font-inter">07:00-08:00 AM</div>
                  </div>
                </div>
                <div><img alt="..." src={horizontal} className="topgap20" /></div>
                <div className="row topgap20 topgap10Media bottomgap20">
                  <div className="col-lg-3 col-md-3">
                    <div className="font-48 font-inter">02</div>
                  </div>
                  <div className="col-lg-1 col-md-1">
                    <div><img alt="..." src={vertical} className="media-hidden" /></div>
                  </div>
                  <div className="col-lg-7 col-md-7">
                    <div className="font-13">Pranayama</div>
                    <div className="font-15 color-gray topgap10">Tue | Thu | Sat</div>
                    <div className="font-14 topgap10 font-inter">07:00-08:00 AM</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="time-block" style={{ backgroundColor: "#DCF2AD" }}>
                <div className="font-26">New York</div>
                <div className="row topgap40 topgap10Media">
                  <div className="col-lg-3 col-md-3">
                    <div className="font-48 font-inter">01</div>
                  </div>
                  <div className="col-lg-1 col-md-1">
                    <div><img alt="..." src={vertical} className="media-hidden" /></div>
                  </div>
                  <div className="col-lg-7 col-md-7">
                    <div className="font-14">Yoga Workout</div>
                    <div className="font-15 color-gray topgap10">Mon | Wed | Fri</div>
                    <div className="font-14 topgap10 font-inter">08:00-09:00 AM</div>
                  </div>
                </div>
                <div><img alt="..." src={horizontal} className="topgap20" /></div>
                <div className="row topgap20 topgap10Media bottomgap20">
                  <div className="col-lg-3 col-md-3">
                    <div className="font-48 font-inter">02</div>
                  </div>
                  <div className="col-lg-1 col-md-1">
                    <div><img alt="..." src={vertical} className="media-hidden" /></div>
                  </div>
                  <div className="col-lg-7 col-md-7">
                    <div className="font-14">Yoga Workout</div>
                    <div className="font-15 color-gray topgap10">Tue | Thu | Sat</div>
                    <div className="font-14 topgap10 font-inter">07:30-08:30 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="time-block" style={{ backgroundColor: "#87B5D0" }}>
                <div className="font-26">California</div>
                <div className="row topgap40 topgap10Media">
                  <div className="col-lg-3 col-md-3">
                    <div className="font-48 font-inter">01</div>
                  </div>
                  <div className="col-lg-1 col-md-1">
                    <div><img alt="..." src={vertical} className="media-hidden" /></div>
                  </div>
                  <div className="col-lg-7 col-md-7">
                    <div className="font-14">Yoga Workout</div>
                    <div className="font-15 color-gray topgap10">Mon | Wed | Fri</div>
                    <div className="font-14 topgap10 font-inter">05:00-06:00 AM</div>
                  </div>
                </div>
                <div><img alt="..." src={horizontal} className="topgap20 " /></div>
                <div className="row topgap20 topgap10Media bottomgap20">
                  <div className="col-lg-3 col-md-3">
                    <div className="font-48 font-inter">02</div>
                  </div>
                  <div className="col-lg-1 col-md-1">
                    <div><img alt="..." src={vertical} className="media-hidden" /></div>
                  </div>
                  <div className="col-lg-7 col-md-7">
                    <div className="font-14">Yoga Workout</div>
                    <div className="font-15 color-gray topgap10">Tue | Thu | Sat</div>
                    <div className="font-14 topgap10 font-inter">04:30-05:30 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="time-block" style={{ backgroundColor: "#DCF2AD" }}>
                <div className="font-26">India</div>
                <div className="row topgap40 topgap10Media">
                  <div className="col-lg-3 col-md-3">
                    <div className="font-48 font-inter">01</div>
                  </div>
                  <div className="col-lg-1 col-md-1">
                    <div><img alt="..." src={vertical} className="media-hidden" /></div>
                  </div>
                  <div className="col-lg-7 col-md-7">
                    <div className="font-14">Yoga Workout</div>
                    <div className="font-15 color-gray topgap10">Mon | Wed | Fri</div>
                    <div className="font-14 topgap10 font-inter">05:30-06:30 PM</div>
                  </div>
                </div>
                <div><img alt="..." src={horizontal} className="topgap20" /></div>
                <div className="row topgap20 topgap10Media bottomgap20">
                  <div className="col-lg-3 col-md-3">
                    <div className="font-48 font-inter">02</div>
                  </div>
                  <div className="col-lg-1 col-md-1">
                    <div><img alt="..." src={vertical} className="media-hidden" /></div>
                  </div>
                  <div className="col-lg-7 col-md-7">
                    <div className="font-14">Yoga Workout</div>
                    <div className="font-15 color-gray topgap10">Tue | Thu | Sat</div>
                    <div className="font-14 topgap10 font-inter">05:00-06:00 AM</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row topgap40">
            <div className="col-lg-3 col-md-3">&nbsp;</div>
            <div className="col-lg-5 col-md-5">
              <div className="feeBlock">
                <div>60$ USD per month</div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="footer">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-12">
            <div className="font-16">Follow Us</div>
            <div><img alt="..." src={verticalLine} className="w-100" /></div>
            <div className="font-16 color-gray"><a href="https://www.youtube.com/channel/UC-8v7YbwgdmiCPpJRUuKeuA" target="_blank" rel="noreferrer">Youtube<span className="right-align"><img alt="..."
              src={gotoArrow} /></span></a></div>
            <div><img alt="..." src={verticalLine} className="w-100" /></div>
            <div className="font-16 color-gray"><a href="https://www.facebook.com/kamalshriyoga" target="_blank" rel="noreferrer">Facebook<span className="right-align"><img alt="..."
              src={gotoArrow} /></span></a></div>
            <div><img alt="..." src={verticalLine} className="w-100" /></div>
            <div className="font-16 color-gray"><a href="https://www.instagram.com/kamalshriwellness" target="_blank" rel="noreferrer">Instagram<span className="right-align"><img alt="..."
              src={gotoArrow} /></span></a></div>
            <div><img alt="..." src={verticalLine} className="w-100" /></div>
            <div className="font-16 color-gray"><a href="https://twitter.com/kamalshriyoga" target="_blank" rel="noreferrer">Twitter<span className="right-align"><img alt="..."
              src={gotoArrow} /></span></a></div>
            <div><img alt="..." src={verticalLine} className="w-100" /></div>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-12">&nbsp;</div>
          <div className="col-lg-2 col-md-2 col-sm-12">
            <div className="font-16">Contact Us via</div>
            <div><img alt="..." src={verticalLine} className="w-100" /></div>
            <div className="font-15 color-gray">kamalshriyoga@gmail.com<span className="right-align"><img alt="..."
              src={emailIcon} /></span></div>
            <div><img alt="..." src={verticalLine} className="w-100" /></div>
            <div className="font-16 color-gray font-inter">+91 9910992506<span className="right-align"><img alt="..."
              src={waIcon2} /><img alt="..."
                src={phoneIcon} /></span></div>
            <div><img alt="..." src={verticalLine} className="w-100" /></div>
            {/* <div className="font-16 color-gray"><a href="www.instagram.com" target="_blank">Instagram<span className="right-align"><img alt="..."
              src={gotoArrow} /></span></a></div>
            <div><img alt="..." src={verticalLine} className="w-100" /></div>
            <div className="font-16 color-gray"><a href="www.twitter.com" target="_blank">Twitter<span className="right-align"><img alt="..."
              src={gotoArrow} /></span></a></div>
            <div><img alt="..." src={verticalLine} className="w-100" /></div> */}
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">&nbsp;</div>
          <div className="col-lg-3 col-md-3 col-sm-12 topgap200Neg">
            <div className="form-container">
              <form ref={form} onSubmit={sendEmail}>
                <div className="mb-3">
                  <input type="text" className="form-control kw-input" placeholder="Name" name='name' />
                </div>
                <div className="mb-3">
                  <input type="text" maxLength={10} className="form-control kw-input" placeholder="Mobile Number" name="mobile" />
                </div>
                <div className="mb-3">
                  <input type="email" className="form-control kw-input" aria-describedby="emailHelp" placeholder="Email" name="email" />
                </div>
                <div className="mb-3">
                  <textarea className="form-control kw-input" rows="3" placeholder="Message" name="message"></textarea>
                </div>
                <div className="mb-3">
                  <button type="submit" className="kw-btn" disabled={isDisabled}><img alt="..." src={contactUs} width="80%" /></button>
                  {
                    isDisabled ? (
                      <div className="greenText">Thank you for contacting us.</div>
                    ):(
                      null
                    )
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
